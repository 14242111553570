<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12  color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
            <SideMenu :indexsec="4"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  data.name,
                  data.price,
                  data.code,
                  file1,
                  category_form,
                  data.descrp,
                  data.status
                )
              "
            >
            <div class="div_EProduct">
              <p>EDITAR PRODUCTO</p>
              </div>

                <a class="btn-ver" :href="'/producto/' + data.url"  target="_blank"> VER PRODUCTO <img src="../../assets/Arrow_6.png" alt=""> </a>
<div class="mtrow"></div>

              <div class="div_EPForm">

              <div class="row mx-0 row-two">
                <div class="EPF2_C1">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                 
                    v-model="data.name"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
                  <div class="">
                  <label class="labelDes color-1" for="inputDes">Descripción</label>
                  <b-form-input
                  
                    v-model="data.descrp"
                    required
                    id="inputDes"
                    type="text"
                  />
                </div>
                <div class="">
                    <label class="labelR color-1" for="inputR">Estado</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.status}}</span></div> 
                      </template>
                          <b-dropdown-item @click="changeStatus('ACTIVO')">
                            {{'ACTIVO'}}
                          </b-dropdown-item>
                           <b-dropdown-item @click="changeStatus('INACTIVO')">
                            {{'INACTIVO'}}
                          </b-dropdown-item>
                    </b-dropdown>
                  </div>
              </div>

                <div class="row mx-0 row-two mtrow">
                <div class="EPF2_C1">
                  <label class="labelNum color-1" for="inputNum">Precio</label>
                  <b-form-input
                  
                    v-model="data.price"
                    required
                    id="inputNum"
                     type="number"
                    step="any"
                  />
                </div>
                <div class="">
                  <label class="labelCod color-1" for="inputCod">Código</label>
                  <b-form-input
                  
                    v-model="data.code"
                    required
                    id="inputCod"
                    type="text"
                    pattern="\d*"
                  />
                </div>
                 <div class="">
                    <label class="labelR color-1" for="inputR">Categoría principal</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{data.category_name}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>
              
              </div>
            
                 <div class="div-image mtrow">
                  <label class="labelImg color-1" for="inputImg">Imagen</label>
                  <b-form-file
                    v-model="file1"
                    id="inputImg"
                   
                    type="file"
                    name="inputImg"
                    :placeholder="'Subir imagen'"
                  />
                  <div id="edit-prod-img" v-if="msgimg == 'success'">
               <!-- <p class="color-1">Imagen del usuario:</p> -->
           
              <img :src="this.image(data.image)"  alt="imagen de usuario">
            </div>
                </div> 


               <div  v-if="this.section !='imagenes'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="editarOtro()"
                      >{{message_btn}}</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>

              
              </div>
            </form>
              



         <!--<hr class="mtrow">-->
          <div class="row mx-0 row-one mtrow">
                  <div class="EPSock_text">
                    <label >Stock</label>
                    
                  </div>
                  <div class="msg_stock ">
                      
                        <p><!--<span class="separador"></span>-->Total: <template v-if="data.total_stock == 0" > <span class="color-2" >{{data.total_stock}}</span>  </template> <template v-else> {{data.total_stock}}</template> </p>
                      
                </div>
                  <div id="btn-agregar-min">
                      <a class="color-4 color-bg-7" :href="'/agregar/stock/'+this.$route.params.id">  <img :src="icon_mas" alt="icon agregar">AGREGAR STOCK</a>
                  </div>
        </div>
   
      
        
     <div  id="section_stock" class="color-bg-4">
      
     <div id="content-stn">
      <div class="div_Stock">
        <div class="row">
              <div class="color-bg-3 title t1"><p>Stock</p></div>
              <div class="color-bg-3 title t1"><p>Talla</p></div>
              <div class="color-bg-3 title t1"><p>Color</p></div>
              <div class="color-bg-6 title t2"><p>Editar</p></div>
              <div class="color-bg-6 title t2"><p>Borrar</p></div>
            </div>
            <div v-if="data.stock != 'No se encontraron coincidencias'">
              <div v-for="(item,key) in filteredItems" class="items" :key="key">
                            <div class="row row-data color-bg-4" id="Stock_transparent">
                                    <div class=" data clmn-one d1 color-bg-4" id="Stock_transparent"><p>{{item.stock}}</p></div>
                                    <div class=" data d1 color-bg-4"><p>{{item.size_name}}</p></div>
                                    <div class=" data d1 color-bg-4"><p>{{item.color_name}} </p></div>
                                    <div class="color-bg-3 data d3"><a :href="'/editar/stock/'+pid+'/'+item.id"><img src="../../assets/edit_1.png" alt="editar icon"></a></div>
                                    <div class=" data d2 color-bg-4" id="Stock_transparent">

                                      <b-btn v-b-modal="'modal-delete'+item.id" variant="outline-secondary" class="eliminar-modal"> <img :src="eliminar" alt="eliminar icon"></b-btn>
                                        <b-modal   :id="'modal-delete'+item.id"   no-close-on-backdrop centered hide-footer hide-header>
                                            <div class="d-block text-center">
                                                <img :src="closeIcon" class="icon3 pt-5"><br>
                                                <p class="modalHeader pt-3 color-6">Eliminar stock</p>
                                                <p class="modalBody color-grey">¿Estás seguro que deseas eliminar este stock?</p>
                                            </div>
                                            <div class="wrapper">
                                                <b-button class="one  py-3 btnleft btns " @click="wait()">Cancelar</b-button>
                                                <b-button class="two btnright btns " @click="eliminarItem(item.id,'stock')">Confirmar</b-button>
                                            </div>
                                        </b-modal>

                                    </div>
                      
                            </div>
                            
                        </div>
            </div>
          <div v-else>
              <div  class="row row-data">
                  <p class="msg-coincidencias color-grey">Este producto no cuenta con stock.</p>
              </div>
          </div>
         </div>
     </div>
    <div id="navigate-buttons">
        <div class="row">
            <button @click="prev" id="btn-prev" class="btn-navigate color-white-F5 color-bg-6"><img src="../../assets/Arrow-12.png"></button>
            <p class="num-pages color-6">{{currentPage}} - {{totalPages}}</p>
            <button @click="next"  id="btn-next" class="btn-navigate color-white-F5 color-bg-6"><img src="../../assets/Arrow-12.png"></button>
            </div>
    </div>
    
 
  </div>


  
   <hr class="mtrow dml">
                  

                  <!--{{images_gallery}} -->
              <form
              @submit.prevent="
                agregarImagen(
                 file2,
                )
              "
            >
              <div class="row mx-0 row-one">
               
                <div class="div-image ">
                  <label class="labelImg2 color-1" for="inputImg2">Imagenes</label>
                  <b-form-file
                    v-model="file2"
                    id="inputImg2"
                    type="file"
                    name="inputImg2"
                    :placeholder="'Subir imagen'"
                  />
                </div>

                 
                
              </div>


              <div class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal "
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="agregarOtro()"
                      >{{message_btn}}</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
              
            
            
           
               <div v-if="this.section=='imagenes'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>





              <div class="images_gallery Div_ImagesCenter">
                    <div class="row gallery_titles">
                        <div class=" gt1">
                          <p>Visualización</p>
                        </div>
                        <div class=" gt2">
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.imagenes != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.imagenes" class="item_gallery" :key="key">
                        <div class="row" v-if="item.type != 'primera'">
                            <div class="gt1">
                              <p> <img :src="urlpath.url()+'/get-product-image/'+item.image" alt=""></p>
                            
                            </div>
                            <div class="gt2">
                              <p class="eliminar-gallery" @click="eliminarImagen(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este producto aun no tiene imagenes.</p>
                        </div>
                  </div>
                   
                    
               </div>

               <!--fin imagenes  --->


               <!--  categorias --->

                 <hr class="mtrow dml">
                  <label id="Label_AgrCanales" class="labelpr color-1 mtrow dml" >Agregar Categorias</label>

                  <div v-if="this.section =='categorias'" class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>
              </div>
                
                  <form
              @submit.prevent="
                agregarCategoria(
                  category_form2
                )
              "
            >
              
              


              <div class="row mx-0 row-two">
               
                <div class="div_Canal">
                    <label id="label_subtitle" class="labelEq color-1" >Categoría</label>
                   
                     <b-dropdown>
                      <template #button-content>
                        <div class="row"><span class="drop-text">{{category_name2}}</span></div> 
                      </template>

                       <div v-for="(item, key) in this.categories" :key="key">
                          <b-dropdown-item @click="changeCategory2(item.id, item.name)">
                            {{item.name}}
                          </b-dropdown-item>
                       </div>
                    </b-dropdown>
                  </div>

              </div>

             


             
              <div id="EditPartbtn_Agregar" class="btn-form-2">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal"
                  type="submit"
                  >AGREGAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">{{message_modal}}</p>
                  </div>
                  <div class="">
                    <b-button class="btn-modal" @click="wait()"
                      >ACEPTAR</b-button
                    >
                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
         
           <div class="canales dvcate">
                    <div id="div_canales" class="row channel_titles">
                       <div>
                          <p>ID</p>
                        </div>
                        <div>
                          <p>Nombre</p>
                        </div>
                       
                        <div>
                          <p>Eliminar</p>
                        </div>
                    </div>
                 
                  <div v-if="data.categorias != 'No se encontraron coincidencias'">
                  <div  v-for="(item,key) in data.categorias" class="item_channel" :key="key">
                        <div class="row">
                          <div>
                         <p>{{item.id}}</p>
                          </div>
                            <div>
                              <p>{{item.category_name}}</p>
                            </div>
                           
                            <div>
                              <p class="eliminar-channel" @click="eliminarCategoria(item.id)">&#10006;</p>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div v-else>
                        <div class="mtrow msg_channels">
                            <p>Este producto aun no tiene categorias asignadas.</p>
                        </div>
                  </div>
                   
                    
           </div>



                <!--- Modal de errores  --->
                    <b-modal
                      id="modal-error"
                      ref="modal-error"
                      no-close-on-backdrop
                      centered
                      hide-footer
                      hide-header
                    >
                      <div class="message-modal">
                        <img :src="closeIcon" class="icon3 pt-5"><br>
                        <p class="text-modal">{{msg}}</p>
                      </div>
                      <div class="">
                        <b-button class="btn-modal" @click="wait()"
                          >Aceptar</b-button
                        >
                        <b-button
                          type="button"
                          aria-label="Close"
                          class="close-modal"
                          @click="wait()"
                          >×</b-button
                        >
                      </div>
                    </b-modal>
                    <!---  --->


          </div>
     
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import editar from '../../assets/editar.png';
import eliminar from '../../assets/eliminar.png';
import i_logout from '../../assets/iconocerrar.png'
import icon_mas from '../../assets/icon-mas.png';
import { mapActions } from "vuex";

let urlpath = require('../../global/url')

export default {
  name: "EditPlayer",
    components: {
      Header,
    SideMenu,
  },
  data() {
    return {
      editar: editar,
      eliminar: eliminar,
      category_form:"",
      status: "",
      msg: "",
      section:'',
      msgimg:"success",
      file1:null,
      file2:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
        icon_mas: icon_mas,
          closeIcon: i_logout, 
     
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
      currentPage: 1,
      itemsPerPage: 4,
      urlpath,
      message_modal:'El producto se guardo correctamente',
      message_btn:'ACEPTAR',
       category_form2 :'',
      category_name2: 'categoría',
      // pattern="[a-zA-Z 0-9]{2,254}{/()+*-_}"
      
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    }, 
  created() {
     let id = this.$route.params.id;
      let payload = {
        id: id,
        option: "product",
      };
     this.getInfoById(payload);
      this.getCategories('Categorías')
   
  },
  computed:{
      pid(){
        return this.$route.params.id
      },
      data() {
      return this.$store.getters["main/data"];
      },
       dataStock() {
       let result =[]
       if(this.data.stock != "No se encontraron coincidencias"){
         result = this.data.stock
       }
      return result
      },
      url() {
        return this.$store.getters["main/baseURL"];
      },
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
       allItems () {
    	return Object.keys(this.dataStock).map(pid => this.dataStock[pid])
    },
  	filteredItems () {
      
      return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
    },
		totalPages () {
     let total = Math.ceil(this.allItems.length / this.itemsPerPage)
     if(total < this.currentPage){
       this.currentPage =1
     }
    	return total
    },
    categories() {
       let allctg =this.$store.getters["categories/data"];
       let result =[]
       let model;
        for(var i =0; i<allctg.length; i++){
         if(allctg[i].parent_name != 'BLOG' && allctg[i].name != 'BLOG'){ //Mostrar solo categorias para ropa.
           if(allctg[i].id== 1){
             model ={
                        id: allctg[i].id,
                        id_parent: allctg[i].id_parent,
                        name: 'NINGUNA',
                        parent_name: allctg[i].parent_name
                      }
          }else{
            model ={
            id: allctg[i].id,
            id_parent: allctg[i].id_parent,
            name: allctg[i].name,
            parent_name: allctg[i].parent_name
            }
          }

           result.push(model)
         }
          
         
       }

      return result
    },
  },
  methods: {
     ...mapActions("main", ["getInfoById"]),
       ...mapActions('categories', ['getCategories']),

    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    submit: async function (  name, price, code,image, id_category, descrp, status_prod) {
      
         let id = this.$route.params.id;

        code = String(code)
        price = String(price)

        this.status=''
        this.msg=''
        
        
        let item = this.data

       id_category== undefined ||  id_category== ''  ||  id_category== null  ? id_category =item.id_category : id_category;
       id_category = String(id_category)
         
       if(image !=null){
           
            if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{

           
            
          var data = new  FormData();
          //Añadimos la imagen seleccionada
          data.append('id', id)
          data.append('image', image);
          data.append('name', name);
          data.append('status_prod', status_prod)
          data.append('code', code);
          data.append('price', price);
          data.append('id_category', id_category);
          data.append('descrp', descrp);
         
          data.append('_method', 'PUT');
          //Enviamos la petición
        

          
          let result = await  this.$store.dispatch("main/editItem",  {option:'product', item:data});
            
              if(result.status=='error'){
                this.status='error'
                this.msg= result.message
              }else{ // success
                  this.showModal();
              }

          }
          
          

      }else{
       
        let product ={
          'image': image,
          'name': name,
          'status_prod':status_prod,
          'price': price,
          'code': code,
          'id_category':id_category,
          'descrp':descrp,
          "id":id
        }
      
      let result = await  this.$store.dispatch("main/editItem",  {option:'product', item:product});
         
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal();
          }
        
      
      }

       
         
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    },
    
     image: function(img){
        let src = this.url +'/get-product-image/'+img;
         this.status_img(img)
        return src
         },
    status_img:async  function (img){
    let userimg =''
      try{
          userimg = await this.$store.dispatch("main/getImage",{image: img, option:'product'})
          if(userimg.data.status=='error'){
              this.msgimg='error'
          }else{
              this.msgimg='success'
          }
      }catch(err){
          this.msgimg='error'
      }
    },
       eliminarItem: async function(id, option){
         console.log(id + ' - '+ option)
        let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
        
         if(result.status=='error'){
           this.msg = result.message;
           this.showModalError();
         }else{
           this.msg='';
           this.wait()
         }
         
        },
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
         changeNum: function(num){
          this.itemsPerPage= num
        },
         changeCategory: function (value, name) {
        
          this.category_form = value;
          this.data.category_name = name;

        },

        changeStatus: function ( name) {
        
      
          this.data.status = name;

        },




     eliminarImagen: async function(id){
       
     let option ='product/image'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              console.log(result)
      if(result.status=='error'){
        this.section='imagenes'
        this.msg = 'Ha ocurrido un error al intentar eliminar la imagen.';
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    
    },
    agregarImagen: async function(image){
      this.message_modal ='La imagen se guardo correctamente'
      this.message_btn ='AÑADIR OTRA'
        let id_product = this.$route.params.id;
          this.status=''
          this.msg=''

     

         if(image == null){
              this.section='imagenes'
            this.status='error'
            this.msg='Imagen no subida'
        }else{

            if(image.size > 1000000){
                this.status='error'
                this.msg= 'Tamaño de imagen no válido, sube imagenes menores a 1 MB.'
               
                
            }else{
          var data = new  FormData();
        
            data.append('image', image);
            data.append('id_product', id_product);
            data.append('_method', 'POST');
          
                
            this.status=''
            this.msg=''
        
            let result = await this.$store.dispatch("main/addItem",  {option:'product/image', item: data});
          
            if(result.status=='error'){
                 this.section='imagenes'
              this.status='error'
              this.msg= result.message
            }else{ // success
              
                this.showModal() 
            }

            }
                

        }

    },

     showModalError() {
            this.$refs["modal-error"].show();
         },

         agregarOtro: function () {
      this.wait();
    },


    filterTitle: function(title) {
      let result = title.replaceAll(" ", "-").replaceAll('/','_');
      return result;
    },


     changeCategory2: function (value, name) {
     
       this.category_form2 = value;
       this.category_name2 = name;

      },

       eliminarCategoria: async function(id){
      
      let option ='product/category'
      let result = await this.$store.dispatch("main/deleteItem", {option: option, id:id});
              
      if(result.status=='error'){
        this.section='categorias'
        this.msg = result.message;
        this.showModalError();
      }else{
        this.section==''
        this.msg='';
        this.wait()
      }
    },
    agregarCategoria: async function(id_category){
        let id_product = this.$route.params.id;
       this.message_modal ='La categoria se agrego correctamente.'

        /*
        console.log(id_product)
        console.log(id_category)*/

        if(id_category == '' || id_category == undefined || id_category==null){
                this.section='categorias'
               this.status='error'
              this.msg='Elige una categoría.'
        }else{

          id_product= String(id_product)
          id_category = String(id_category)
          let data={
            "id_category":id_category,
            "id_product":id_product
          }

          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'product/category', item: data});
        
          if(result.status=='error'){
            this.section='categorias'
            this.status='error'
            this.msg= result.message
          }else{ // success
              this.showModal() 
          }
        }
    },
        
  },
};
</script>
<style scoped>
.dvcate{
  margin-bottom: 5vw !important;
}
.dml{
  margin-left: 10vw !important;
}
</style>


